import gql from "graphql-tag";

const GET_CONTACT_IN_ALL_PLATFORMS = gql`
  query($filters: ContactFilterInput!) {
    contacts(filters: $filters) {
      okta_user {
        id
        profile {
          email
          firstName
          lastName
          login
          mobilePhone
        }
        groups {
          profile {
            description
            name
          }
        }
        status
        statusChanged
      }
      servicenow_user {
        active
        email
        first_name
        last_name
        mobile_phone
        sys_created_on
        sys_updated_on
      }
      active
      createdon
      firstname
      lastname
      emailaddress1
      mobilephone
      modifiedon
    }
  }
`;

export default GET_CONTACT_IN_ALL_PLATFORMS;
