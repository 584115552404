import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "@/core/services/notification.service";
import DialogService from "@/core/services/dialog.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import GET_CONTACT_IN_ALL_PLATFORMS from "@/core/services/graphql/queries/dynamics365/contact";
import MUTATION_INITIATE_SYNC_CONTACT from "@/core/services/graphql/mutation/dynamics365/contact";
import UtilService from "@/core/services/util.service";
import DocxGeneratorService from "@/core/services/docxgenerator.service";
import {
  ADD_CONTACT_SYNCED_HISTORY,
  CLEAR_CONTACT_SYNCED_HISTORY
} from "@/store/modules/contactSyncedHistory.module";
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "CommandBaseContact",
  data: () => ({
    description_list: [],
    contact: null,
    formValid: false,
    email: "",
    isLoading: false,
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ]
  }),
  apollo: {
    contacts: {
      query: () => GET_CONTACT_IN_ALL_PLATFORMS,
      skip() {
        return true;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      result({ data, networkStatus }) {
        if (networkStatus == 7) {
          if (data.contacts && data.contacts.length > 0) {
            this.contact = data.contacts[0];
            this.updateDescriptionList();
          } else {
            NotificationService.dataNotFound();
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Integration Office", route: "navigation-menu" },
      { title: "Command Base Contact", route: "command-base-contact" }
    ]);

    this.$store.dispatch(CLEAR_CONTACT_SYNCED_HISTORY);
  },
  created() {},
  computed: {
    ...mapGetters(["getContactSyncedHistory"])
  },
  methods: {
    updateDescriptionList() {
      if (this.contact) {
        this.description_list = [
          {
            description: "Status",
            dynamicsCE: this.contact.active ? "ACTIVE" : "INACTIVE",
            okta: this.contact.okta_user ? this.contact.okta_user.status : "",
            servicenow: this.contact.servicenow_user
              ? this.contact.servicenow_user.active
                ? "ACTIVE"
                : "INACTIVE"
              : "",
            isComparable: true
          },
          {
            description: "Email",
            dynamicsCE: this.contact.emailaddress1,
            okta: this.contact.okta_user
              ? this.contact.okta_user.profile.email
              : "",
            servicenow: this.contact.servicenow_user
              ? this.contact.servicenow_user.email
              : "",
            isComparable: true
          },
          {
            description: "Firstname",
            dynamicsCE: this.contact.firstname,
            okta: this.contact.okta_user
              ? this.contact.okta_user.profile.firstName
              : "",
            servicenow: this.contact.servicenow_user
              ? this.contact.servicenow_user.first_name
              : "",
            isComparable: true
          },
          {
            description: "Lastname",
            dynamicsCE: this.contact.lastname,
            okta: this.contact.okta_user
              ? this.contact.okta_user.profile.lastName
              : "",
            servicenow: this.contact.servicenow_user
              ? this.contact.servicenow_user.last_name
              : "",
            isComparable: true
          },
          {
            description: "Phone",
            dynamicsCE: this.contact.mobiphone,
            okta: this.contact.okta_user
              ? this.contact.okta_user.profile.mobiPhone
              : "",
            servicenow: this.contact.servicenow_user
              ? this.contact.servicenow_user.mobile_phone
              : "",
            isComparable: true
          },
          {
            description: "Created",
            dynamicsCE: dayjs(this.contact.createdon).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            okta: "",
            servicenow: this.contact.servicenow_user
              ? dayjs(
                  this.contact.servicenow_user.sys_created_on,
                  "DD.MM.YYYY HH:mm:ss"
                ).format("DD-MM-YYYY HH:mm:ss")
              : "",
            isComparable: false
          },
          {
            description: "Updated",
            dynamicsCE: dayjs(this.contact.modifiedon).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            okta: "",
            servicenow: this.contact.servicenow_user
              ? dayjs(
                  this.contact.servicenow_user.sys_updated_on,
                  "DD.MM.YYYY HH:mm:ss"
                ).format("DD-MM-YYYY HH:mm:ss")
              : "",
            isComparable: false
          },
          {
            description: "Groups",
            dynamicsCE: "",
            okta: this.contact.okta_user ? this.contact.okta_user.groups : "",
            servicenow: "",
            isComparable: false
          }
        ];
      }
    },
    search() {
      this.$refs.form.validate();
      if (this.formValid && this.email) {
        this.contact = null;
        this.description_list = [];
        this.$apollo.queries.contacts.setVariables({
          filters: {
            emailaddress1: {
              value: this.email.trim()
            }
          }
        });
        this.$apollo.queries.contacts.skip = false;
        this.$apollo.queries.contacts.refetch();
      }
    },
    async initiateSync() {
      if (this.email) {
        let message = `Are you sure you want to initiate a synchronisation of the contact <b>${this.email}</b>?`;

        if (
          await DialogService.confirm(this, "Confirmation", message, {
            manualControl: true
          })
        ) {
          this.$apollo
            .mutate({
              mutation: MUTATION_INITIATE_SYNC_CONTACT,
              variables: {
                contact: {
                  email_address: this.email
                }
              }
            })
            .then(res => {
              if (
                res.data.initiateSyncContact &&
                res.data.initiateSyncContact.result &&
                res.data.initiateSyncContact.result.conversation_id
              ) {
                let conversation_id =
                  res.data.initiateSyncContact.result.conversation_id;
                let message = `Conversation Id </br> <b class="font-size-lg">${conversation_id}</b></br> </br>Please keep this Conversation Id as a reference for our development team if the sync does not work properly You can contact the development team on Slack: #sentia-dev-sala.`;
                DialogService.updateBodyContent(this, "Complete", message);
                setTimeout(() => {
                  NotificationService.success(
                    "Successfully submitted your change"
                  );
                }, 100);

                this.$store.dispatch(ADD_CONTACT_SYNCED_HISTORY, {
                  contact_email: this.email,
                  conversation_id: conversation_id,
                  created: dayjs()
                });
              }
            });
        }
      }
    },
    getWarningCssClass(item, type) {
      if (
        item.isComparable &&
        type == "Any" &&
        item.dynamicsCE &&
        (item.dynamicsCE != item.okta ||
          item.dynamicsCE != item.servicenow ||
          item.okta != item.servicenow)
      ) {
        return "text-danger";
      } else if (
        item.isComparable &&
        type == "ServiceNow" &&
        item.dynamicsCE &&
        item.dynamicsCE != item.servicenow
      ) {
        return "text-danger";
      } else if (
        item.isComparable &&
        type == "Okta" &&
        item.dynamicsCE &&
        item.dynamicsCE != item.okta
      ) {
        return "text-danger";
      }
    },
    exportCsv() {
      if (this.contact) {
        let headers = {
          description: "Description",
          dynamicsCE: "DynamicsCE",
          okta: "Okta",
          servicenow: "ServiceNow"
        };

        let items = [];
        this.description_list.forEach(function (item) {
          var oktaString = item.okta;
          if (Array.isArray(item.okta)) {
            var okta_list = [];
            item.okta.forEach(function (okta_item) {
              okta_list.push(okta_item.profile.name);
            });

            oktaString = okta_list.join("$");
          }

          items.push({
            description: item.description,
            dynamicsCE: item.dynamicsCE,
            okta: oktaString,
            servicenow: item.servicenow
          });
        });

        var fileName = `Control_Center_Contact_${this.email}`;
        UtilService.exportCSVFile(headers, items, fileName);
      }
    },
    exportDocx() {
      if (this.contact) {
        var title = `Control_Center_Contact_${this.email}`;
        var templateUrl = "/templates/Control_Center_Contact_template.docx";
        var items = [];
        this.description_list.forEach(function (item) {
          var okta_list = [];
          if (Array.isArray(item.okta)) {
            item.okta.forEach(function (group) {
              okta_list.push({ name: group.profile.name });
            });
          } else {
            okta_list.push({ name: item.okta });
          }

          items.push({
            description: item.description,
            dynamicsCE: item.dynamicsCE,
            okta: okta_list,
            servicenow: item.servicenow
          });
        });

        let data = {
          fields: items,
          contact_email: this.email,
          creation_date: dayjs().format("DD/MM/YYYY")
        };

        DocxGeneratorService.generate({
          title: title,
          templateUrl: templateUrl,
          data: data
        });
      }
    },
    isArray(item) {
      if (Array.isArray(item)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
