import gql from "graphql-tag";

const MUTATION_INITIATE_SYNC_CONTACT = gql`
  mutation($contact: InitiateSyncContactInput!) {
    initiateSyncContact(contact: $contact) {
      result {
        conversation_id
        event_ids
      }
      status
    }
  }
`;

export default MUTATION_INITIATE_SYNC_CONTACT;
